<template>
  <div class="dapp-box">
    <div class="w">
      <div class="title">
        <!-- PSC币特征 -->
        {{ $t("study.tokenBox2T1") }}
      </div>
      <div class="gang"></div>
      <div class="subTitle">
        <!-- PSC是一种加密货币。这是稀缺的数字货币，您可 -->
        {{ $t("study.tokenBox2T2") }}
      </div>
      <div class="box">
        <div
          class="wl-item2"
          v-for="(item, index) in $t('study.psctokenBox2List')"
          :key="index"
        >
          <div class="img">
            <img
              :src="
                require(`../../assets/images/v4/study/tokenBox2${
                  index + 1
                }.png`)
              "
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="android">{{ item.subTitle }}</div>
          <div class="p pfont">
            {{ item.text }}
          </div>
          <!-- <div class="more">
            <a href="javascript:;">
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use>
              </svg>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>
<style lang="less" scoped>
.dapp-box {
  padding-top: 100px;
  padding-bottom: 250px;
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  background-repeat: no-repeat;
  background-position: 0 60%;
  .title {
    font-size: 70px;
    font-weight: bold;
    color: #333333;
  }
  .gang {
    width: 60px;
    height: 7px;
    margin-top: 17px;
    background: linear-gradient(90deg, #015eea, #00c0fa);
  }
  .subTitle {
    width: 881px;
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    color: #999999;
    margin-top: 38px;
  }
  .box {
    margin-top: 100px;
    // background-color: pink;
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .wl-item2 {
      width: 446px;
      // height: 416px;
      transition: 0.5s;
      border: 2px solid #0000;
      // background: none;
      // background: linear-gradient(-45deg, #fff, #fff);
      .android {
        font-size: 14px;
        margin-top: 5px;
        font-weight: 400;
        color: #cccccc;
      }
      .link {
        margin-bottom: 0px;
      }
      .p {
        margin-top: 15px;
      }
    }
    .wl-item2:hover {
      background: #015eea;
      // background: linear-gradient(-45deg, #015eea, #00c0fa);
      background: #e9f5ff;
      border: 2px solid #015fea;
    }
  }
}
</style>
