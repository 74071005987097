<template>
  <div class="com-item">
    <div class="w">
      
      <div class="t2">
        <!-- 我们数字未来的货币 -->
        {{ $t("study.tokenBox1T2") }}
      </div>
      <div class="p pfont">
        <!-- PSC是数字化的全球性资金。 -->
        {{ $t("study.tokenBox1P1") }} <br />
        <!-- 这是PSC应用中的货币。 -->
        {{ $t("study.tokenBox1P2") }}
      </div>
      <div class="butBox">
        <div class="but1" @click="uti.goPath(store.link.study.tokenBox1But)">
          <!-- 获取PSC-Swap -->
          {{ $t("study.tokenBox1But") }}
        </div>
      </div>

      <div class="box">
        <div class="top">
          <!-- 当前PSC价格 (美元) -->
          {{ $t("study.tokenBox1BoxT") }}
        </div>
        <div class="bigNum">${{ homePrice }}</div>
        <div class="bot" v-if="false">
          <div class="span">
            4.65%<svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-youshang01"></use>
            </svg>
          </div>
          <div class="mini">
            (
            <!-- 最近24小时 -->
            {{ $t("study.tokenBox1BoxB") }}
            )
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      homePrice:localStorage.getItem("homePrice")
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  height: 100vh;
  box-shadow: 0px 2px 8px 0px rgba(212, 217, 250, 0.5);
  background-color: #e8f3ff;
  background-image: url("../../assets/images/v4/study/tokenBox1BG.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  .w {
    // background-color: pink;
    height: 100vh;
    padding-top: 150px;
    box-sizing: border-box;
    .t1 {
      height: 29px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
    .t2 {
      margin-top: 65px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
    }
    .p {
      width: 783px;
      font-size: 24px;
      line-height: 35px;
      font-weight: 500;
      color: #666666;
    }
    .butBox {
      display: flex;
      margin-top: 41px;
      text-align: center;
      .but1 {
        display: inline-block;
        padding: 0 42px;
        height: 54px;
        line-height: 54px;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 50px;
      }
    }
    .box {
      width: 520px;
      // height: 250px;
      padding-bottom: 50px;
      margin-top: 74px;
      background: linear-gradient(180deg, #2164fc, #40c3f9);
      border-radius: 10px;
      padding-top: 34px;
      box-sizing: border-box;
      text-align: center;
      .top {
        height: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
      .bigNum {
        height: 58px;
        font-size: 60px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 34px;
      }
      .bot {
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 44px;
        justify-content: space-around;
        // justify-content: center;
        .icon {
          font-size: 20px;
        }
        .mini {
          display: inline-block;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
