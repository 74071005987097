<template>
  <div class="study-pscToken">
    <token-box-1></token-box-1>
    <token-box-2></token-box-2>
  </div>
</template>
<script>
import TokenBox1 from "./token-box1.vue";
import TokenBox2 from "./token-box2.vue";
export default {
  components: { TokenBox1, TokenBox2 },
  data() {
    return {};
  },
};
</script>
